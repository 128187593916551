import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  DrupalUserEntity,
  JwtToken,
  RegistrationRequest,
} from '@pm/auth/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';

@Injectable({
  providedIn: 'root',
})
export class PmAuthDataService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private _http: HttpClient,
  ) {}

  submitLoginForm$(login: string, password: string) {
    const headers = new HttpHeaders({
      Authorization: 'Basic ' + btoa(login + `:` + password),
    });
    return this._http.get<JwtToken>(this.appConfig.api + '/jwt/token', {
      headers,
      params: {
        _format: 'json',
      },
    });
  }

  submitRegistrationForm$(payload: RegistrationRequest) {
    const locale = this.appConfig.localeCode
      ? `/${this.appConfig.localeCode}`
      : '/en';

    const { phone: login, password, email } = payload;

    return this._http.post<DrupalUserEntity>(
      this.appConfig.api + locale + '/user/register',
      {
        name: [{ value: login }],
        pass: [{ value: password }],
        mail: [{ value: email }],
        preferred_langcode: [{ value: this.appConfig.localeCode }],
        field_phone: [{ value: login }],
        field_forename: [{ value: payload.field_forename }],
        field_surname: [{ value: payload.field_surname }],
      },
      {
        params: {
          _format: 'json',
        },
      },
    );
  }

  resetPassword$(login: string) {
    return this._http.patch<{
      status: string[];
    }>(
      this.appConfig.api + '/api/v1/user',
      {
        reset: {
          field_phone: login,
        },
      },
      {
        params: {
          _format: 'json',
        },
      },
    );
  }

  createSsoToken$() {
    return this._http.get<string>(this.appConfig.api + '/api/v1/sso');
  }

  refreshAccessToken$(refreshToken: string) {
    return this._http.get<JwtToken>(this.appConfig.api + '/jwt/refresh', {
      params: {
        _format: 'json',
      },
      headers: {
        Authorization: 'Bearer ' + refreshToken,
      },
    });
  }
}
