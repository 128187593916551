import {
  BehaviorSubject,
  Subject,
  catchError,
  from,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';

import { PmAuthService, RegistrationRequest } from '@pm/auth/utils';

import { PmAuthDataService } from '../services';

export class PmAuthBloc {
  isLoggined$ = this._authService.isLoggedIn$;

  error$ = new Subject<string>();
  success$ = new Subject<boolean>();

  processing$ = new BehaviorSubject<boolean>(false);

  constructor(
    private _authDrupalService: PmAuthDataService,
    private _authService: PmAuthService,
  ) {}

  submitLoginForm(login: string, password: string) {
    this._authDrupalService
      .submitLoginForm$(login, password)
      .pipe(take(1))
      .subscribe({
        next: (v) => this._authService.setTokens(v),
        error: this.mapAuthError,
      });
  }

  submitRegistrationForm(payload: RegistrationRequest) {
    const { phone, password } = payload;
    this.processing$.next(true);
    this._authDrupalService
      .submitRegistrationForm$(payload)
      .pipe(
        switchMap(() =>
          this._authDrupalService.submitLoginForm$(phone, password),
        ),
        catchError((error) => {
          this.mapAuthError(error);
          this.processing$.next(false);
          return throwError(() => error);
        }),
      )
      .subscribe({
        next: (v) => this._authService.setTokens(v),
      });
  }

  resetPassword(login: string) {
    this._authDrupalService
      .resetPassword$(login)
      .pipe(take(1))
      .subscribe({
        next: (v) => this.success$.next(true),
        error: this.mapAuthError,
      });
  }

  logout() {
    this._authService.signOut();
  }

  redirectToDashboard() {
    this._authService.openDashboard();
  }

  refreshToken() {
    from(this._authService.getRefreshToken())
      .pipe(
        switchMap((refreshToken) =>
          this._authDrupalService.refreshAccessToken$(refreshToken as string),
        ),
        take(1),
      )
      .subscribe({
        next: (v) => this._authService.setTokens(v),
        error: this.mapAuthError,
      });
  }

  private mapAuthError = (error: { message: string; status: number }) => {
    const { message, status } = error;

    switch (status) {
      case 401:
        this.error$.next('Invalid credentials');
        break;
      case 403:
        this.error$.next('Invalid credentials');
        break;
      case 404:
        this.error$.next('Invalid credentials');
        break;
      case 500:
        this.error$.next('Server error');
        break;
      case 422: {
        const message = error.message.split('\n')[1];
        this.error$.next(message ?? 'Please check inputed information');
        break;
      }
      default:
        this.error$.next('Problem with authorization. Please try again later.');
        break;
    }
  };
}
