import { MenuItem } from 'primeng/api';

import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { PmAuthBloc, PmAuthDataService } from '@pm/auth/data';
import { PmAuthService } from '@pm/auth/utils';
import { APP_CONFIG, AppConfig } from '@pm/config';
import { Observable, Subscription, map, switchMap } from 'rxjs';

@Component({
  selector: 'pm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  bloc = new PmAuthBloc(this._authDrupalService, this._authService);
  @Input()
  theme: 'light' | 'dark' = 'light';

  contactEmail = this.appConfig.contactEmail;
  items: MenuItem[] = [];
  languages = [
    { name: 'ENG', code: 'en' },
    { name: 'UKR', code: 'uk' },
  ];

  isLoggedIn$ = this._authService.isLoggedIn$;
  translations$!: Observable<unknown>;

  isBlogEnabled: boolean | undefined;

  resizeSubscription!: Subscription;

  constructor(
    private _authService: PmAuthService,
    private _authDrupalService: PmAuthDataService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private _transloco: TranslocoService,
    private readonly _breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit() {
    this.isBlogEnabled = this.appConfig.isBlogEnabled;

    this.resizeSubscription = this._breakpointObserver
      .observe([Breakpoints.TabletPortrait, Breakpoints.Handset])
      .pipe(
        switchMap((state: BreakpointState) =>
          this._authService.isLoggedIn$.pipe(map(() => state)),
        ),
      )
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.items = [
            ...this._getMenuItems(),
            ...this._getAccountMenuItems(),
          ];
        } else {
          this.items = this._getMenuItems();
        }
      });
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
  }

  goToDashboard() {
    this.bloc.redirectToDashboard();
  }

  signOut() {
    this._authService.signOut();
  }

  goToSignIn() {
    window.location.href = '/auth/signin';
  }

  goToSignUp() {
    window.location.href = '/auth/signup';
  }

  private _getMenuItems() {
    return [
      this._mapTrademarkMenuItems(),
      {
        label: this._transloco.translate('ui.about-us'),
        routerLink: '/about',
      },
      {
        label: this._transloco.translate('ui.our-clients'),
        routerLink: '/clients',
      },
      {
        label: this._transloco.translate('ui.domains'),
        routerLink: '/domains',
      },
      // {
      //   label: this._transloco.translate('ui.term-and-conditions'),
      //   routerLink: '/terms',
      // },
    ];
  }

  private _getAccountMenuItems() {
    if (this._authService.isLoggedIn$.value) {
      return [
        {
          label: this._transloco.translate('ui.dashboard'),
          command: () => this.goToDashboard(),
          icon: 'pi pi-th-large',
        },
        {
          label: this._transloco.translate('ui.sign-out'),
          command: () => this.signOut(),
          icon: 'pi pi-sign-out',
        },
      ];
    }
    return [
      {
        label: this._transloco.translate('ui.sign-in'),
        command: () => this.goToSignIn(),
        icon: 'pi pi-sign-in',
      },
      {
        label: this._transloco.translate('ui.sign-up'),
        command: () => this.goToSignUp(),
        icon: 'pi pi-user',
      },
    ];
  }

  private _mapTrademarkMenuItems() {
    const inEuLabel = this._transloco.translate('ui.trademark-in-eu');

    if (this.appConfig.isEuPageEnabled) {
      const countryCode = this.appConfig.mainCountry || '';
      const labelText = this._transloco.translate('ui.trademark-in', {
        country: countryCode.toUpperCase(),
      });
      const currentCountryMenuItem = {
        label: `<div class="flex gap-2 w-20rem"><div class="fi fi-${countryCode.toLowerCase()} border-round"></div><div>${labelText}</div></div>`,
        escape: false,
        routerLink: '/',
      };

      return {
        label: this._transloco.translate('ui.trademark-registration'),
        items: [
          currentCountryMenuItem,
          {
            label: `<div class="flex gap-2 w-20rem"><div class="fi fi-eu border-round"></div><div>${inEuLabel}</div></div>`,
            escape: false,
            routerLink: 'eu',
          },
        ],
      };
    } else {
      if (this.appConfig.mainCountry?.toUpperCase() === 'EU') {
        const localWebsites = ['es', 'pl', 'pt'];
        const currentCountryMenuItem = {
          label: `<div class="flex gap-2 w-20rem"><div class="fi fi-eu border-round"></div><div>${inEuLabel}</div></div>`,
          escape: false,
          routerLink: '/',
        };

        const items = localWebsites.map((countryCode) => {
          const labelText = this._transloco.translate('ui.trademark-in', {
            country: countryCode.toUpperCase(),
          });
          return {
            label: `<div class="flex gap-2 w-20rem"><div class="fi fi-${countryCode.toLowerCase()} border-round"></div><div>${labelText}</div></div>`,
            escape: false,
            url: `https://profitmark.${countryCode}`,
          };
        });

        return {
          label: this._transloco.translate('ui.trademark-registration'),
          items: [currentCountryMenuItem, ...items],
        };
      }
      return {
        label: this._transloco.translate('ui.trademark-registration'),
        routerLink: '/',
      };
    }
  }
}
